/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CartType } from "./types";

const items =
  localStorage.getItem("cartItems") !== null
    ? JSON.parse(localStorage.getItem("cartItems")!)
    : [];

const initialStateValue: CartType = {
  isActive: false,
  isCheckout: false,
  isChecked: false,
  address: "",
  subCart: null,
  cart: items,
  isSame: true,
  available: true,
  commission: 0,
  delivery_charge: 0,
  discount: "",
  serviceFee: 0,
  subTotal: 0,
  tax: 0,
  country: {
    country_currency: "",
    country_currency_symbol: "",
    country_name: "",
    country_phone_code: "",
    created_at: "",
    id: 0,
    operating: 0,
    updated_at: "",
  },
  taxSubTotal: 0,
  total: 0,
  establishment_id: 0,
  establishment: null,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: { value: initialStateValue },
  reducers: {
    addToCart: (state, action) => {
      const itemInCart = state.value.cart.find(
        (item) => item.menu_id === action.payload.menu_id
      );
      if (itemInCart) {
        itemInCart.menu_addons = action.payload.menu_addons;
        itemInCart.menu_variation = action.payload.menu_variation;
        if (itemInCart.menu_quantity !== action.payload.menu_quantity) {
          itemInCart.menu_quantity = action.payload.menu_quantity;
        }
      } else {
        state.value.cart.push({
          ...action.payload,
          quantity: 1,
          menu_total: action.payload.menu_total,
        });
      }
      localStorage.setItem(
        "cartItems",
        JSON.stringify(state.value.cart.map((item) => item))
      );
    },

    incrementQuantity: (state, action) => {
      const item = state.value.cart.find(
        (item) => item.menu_id === action.payload
      );
      if (item) {
        item.menu_quantity++;
        item.menu_price = Number(item.menu_price) * item.menu_quantity;
      }
      localStorage.setItem(
        "cartItems",
        JSON.stringify(state.value.cart.map((item) => item))
      );
    },
    decrementQuantity: (state, action) => {
      const item = state.value.cart.find(
        (item) => item.menu_id === action.payload
      );
      if (item) {
        if (item.menu_quantity === 1) {
          item.menu_quantity = 1;
        } else {
          item.menu_quantity--;
        }
      }
      localStorage.setItem(
        "cartItems",
        JSON.stringify(state.value.cart.map((item) => item))
      );
    },
    removeItem: (state, action) => {
      const removeItem = state.value.cart.filter(
        (item) => item.menu_id !== action.payload
      );
      state.value.cart = removeItem;
      localStorage.setItem(
        "cartItems",
        JSON.stringify(
          state.value.cart.filter((item) => item.menu_id !== action.payload)
        )
      );
    },
    clearCart: (state) => {
      localStorage.removeItem("cartItems");
      localStorage.removeItem("active-vendor");
      localStorage.removeItem("foodItem");
      state.value.cart = [];
      // state = initialStateValue;
    },

    setCart: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    addToSubCart: (state, action) => {
      state.value.subCart = action.payload;
      localStorage.setItem("foodItem", JSON.stringify(state.value.subCart));
    },
    incrementSubCartQuantity: (state, action) => {
      const item = state.value.subCart;

      if (item!.menu_id === action.payload) {
        item!.menu_quantity++;
        item!.menu_total = Number(item!.menu_price) * item!.menu_quantity;
      }
      // localStorage.setItem(
      //   "value.subCartItems",
      //   JSON.stringify(state.value.subCart.map((item) => item))
      // );
    },
    decrementSubCartQuantity: (state, action) => {
      const item = state.value.subCart;
      if (item!.menu_id === action.payload) {
        if (item!.menu_quantity === 1) {
          item!.menu_quantity = 1;
        } else {
          item!.menu_quantity--;
          item!.menu_total = Number(item!.menu_price) * item!.menu_quantity;
        }
      }
      // localStorage.setItem(
      //   "value.subCartItems",
      //   JSON.stringify(state.value.subCart.map((item) => item))
      // );
    },
    addAddonsItemCart: (state, action) => {
      const addonsInItem = state.value.subCart!.menu_addons.find(
        (item) => item.addons_id === action.payload.addons_id
      );
      if (addonsInItem) {
        addonsInItem.addons_quantity++;
      } else {
        state.value.subCart!.menu_addons.push({
          ...action.payload,
          quantity: action.payload.quantity,
        });
      }
    },
    decrementAddonQuantity: (state, action) => {
      const item = state.value.subCart!.menu_addons.find(
        (item) => item.addons_id === action.payload
      );
      if (item) {
        if (item.addons_quantity === 1) {
          item.addons_quantity = 1;
        } else {
          item.addons_quantity--;
        }
      }
    },
    addRequiredVariationsItemCart: (state, action) => {
      const newVariation = {
        ...action.payload,
        quantity: action.payload.quantity,
      };

      // Find the index of any existing required variation in the cart
      const existingRequiredIndex =
        state.value.subCart!.menu_variation.findIndex(
          (item) => item.required === true
        );

      if (existingRequiredIndex !== -1) {
        // If a required variation exists, update it with the new selected one
        state.value.subCart!.menu_variation[existingRequiredIndex] =
          newVariation;
      } else {
        // If no required variation exists, add the new selected variation to the cart
        state.value.subCart!.menu_variation.push(newVariation);
      }
    },
    addVariationsItemCart: (state, action) => {
      const variationInItem = state.value.subCart!.menu_variation.find(
        (item) => item.label === action.payload.label
      );

      if (variationInItem) {
        variationInItem.quantity++;
      } else {
        state.value.subCart!.menu_variation.push({
          ...action.payload,
          quantity: action.payload.quantity,
        });
      }
    },
    removeAddon: (state, action) => {
      const removeAddon = state.value.subCart!.menu_addons.filter(
        (item) => item.addons_id !== action.payload
      );
      state.value.subCart!.menu_addons = removeAddon;
    },
    removeVariation: (state, action) => {
      const removeVariation = state.value.subCart!.menu_variation.filter(
        (item) => item.label !== action.payload
      );
      state.value.subCart!.menu_variation = removeVariation;
    },
  },
});

export const {
  addToCart,
  clearCart,
  decrementQuantity,
  incrementQuantity,
  removeItem,
  setCart,
  decrementSubCartQuantity,
  incrementSubCartQuantity,
  addAddonsItemCart,
  addToSubCart,
  removeAddon,
  addVariationsItemCart,
  addRequiredVariationsItemCart,
  removeVariation,
  decrementAddonQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
